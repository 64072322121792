import React, { useState, useEffect, useRef } from 'react';
import Header from '../components/header';
import InterventionForm from '../components/interventionForm';
import AttendanceForm from '../components/attendanceForm';
import HomeForm from '../components/homeForm';
import RecapElem from '../components/recapElem';
import handleExpiredSession from '../utils/Session';
import { SendGETRequest } from '../utils/FetchUtil';
import { convertHourGood } from '../components/attendanceForm';

export default function Home(props) {
    var formDate = undefined;
    var formUser = {};

    var attendanceList = {};

    var formVehicules = [];
    var formChantiers = [];
    var form402 = [];

    var carouselRedirection = false;
    const infoRedirectionRef = useRef(null);

    var recapUpdaterFunc = undefined;
    function sendRecapUpdater(updater) {
        recapUpdaterFunc = updater;
    }

    var positionCarrousel = 0;

    const [isLoading, setIsLoading] = useState(true);
    const [apiInformationData, setApiInformationData] = useState({});
    const [apiInformationError, setApiInformationError] = useState(undefined);

    function hasExpiredError() {
        return !isLoading && apiInformationData && apiInformationError && apiInformationData.tokenExpired;
    }

    function hasOtherError() {
        return !isLoading && apiInformationError;
    }

    useEffect(() => {
        SendGETRequest('/api/data/get_all_information', setApiInformationData, setApiInformationError, setIsLoading);
    }, []);

    // récupération de toutes les dates des fiches existantes => éviter de remplir tout une fiche pour rien
    const [isLoadingSheetsDate, setIsLoadingSheetsDate] = useState(true);
    const [apiSheetsDate, setApiSheetsDate] = useState({});
    const [apiSheetsDateError, setApiSheetsDateError] = useState(undefined);

    // récupération de toutes les dates des fiches existantes
    useEffect(() => {
        SendGETRequest('/api/data/get_all_dates', setApiSheetsDate, setApiSheetsDateError, setIsLoadingSheetsDate);
    }, []);

    useEffect(() => {
        if (!isLoading && !apiInformationError && props.presetData) {
            // Pour chaque véhicule des données de pré-remplissage
            props.presetData.vehicules.forEach((veh) => {
                // Si il n'est pas dans la liste reçue du serveur, on l'ajoute dans les données
                if (!apiInformationData.vehicules.find((v) => v.code === veh.code)) {
                    apiInformationData.vehicules.push(veh);
                }
            });

            // Pour chaque chantier des données de pré-remplissage
            props.presetData.chantiers.forEach((cha) => {
                // Si sa commune n'est pas dans la liste reçue du serveur, on l'ajoute dans les données
                if (!apiInformationData.communes.find((c) => c.code === cha.commune.code)) {
                    apiInformationData.communes.push(cha.commune);
                }

                // Si sa rivière n'est pas dans la liste reçue du serveur, on l'ajoute dans les données
                if (!apiInformationData.rivieres.find((r) => r.code === cha.riviere.code)) {
                    apiInformationData.rivieres.push(cha.riviere);
                }

                // Pour chaque activité du chantier (des données de pré-remplissage)
                cha.activites.forEach((act) => {
                    // Si ce n'est pas dans la liste reçue du serveur, on l'ajoute dans les données
                    if (!apiInformationData.activites.find((a) => a.code === act.code)) {
                        apiInformationData.activites.push(act);
                    }
                });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]);

    // Quand l'utilisateur appui sur 'suivant' dans le 'HomeForm'
    function handleHomeFormSubmit(homeFormState) {
        formDate = homeFormState.formDate;
        formUser = homeFormState.formUser;
        recapUpdaterFunc && recapUpdaterFunc((x) => !x);
    }

    function handleAttendanceSubmit(data, totals) {
        // Transformer en ancien format
        attendanceList = { StagiaireCB: {} };
        Object.keys(data).forEach((k) => {
            attendanceList[k] = {};
            attendanceList[k + 'TOTAL'] = totals[k];

            data[k].users.forEach((user) => {
                attendanceList[k][user.id] = { nbHeure: convertHourGood(user.nbHeure), fullName: user.fullName };
            });
        });
    }

    function handleInterventionSubmit(interventionState) {
        formChantiers = interventionState.chantiers;
        form402 = interventionState.lst402;
        formVehicules = [];
        Object.values(interventionState.vehicules).forEach((veh) => formVehicules.push(veh));
        recapUpdaterFunc && recapUpdaterFunc((x) => !x);
    }

    /*const handlePositionCarrousel = (valeur) => {
        setPositionCarrousel(valeur);
    };*/

    function getFormData() {
        // Validation de la validité des chantiers (dans le cas de la fiche sans "chantier")
        let listeChantiersValide = [];
        formChantiers.forEach((chantier) => {
            if (chantier.commune && chantier.riviere && chantier.activites) {
                listeChantiersValide.push(chantier);
            }
        });
        formChantiers = listeChantiersValide;

        return {
            date: formDate,
            user: formUser,
            vehicules: formVehicules,
            chantiers: formChantiers,
            lst402: form402,
            attendance: attendanceList,
        };
    }

    function handlePositionCarousel(val) {
        let oldVal = positionCarrousel;
        positionCarrousel = val;

        // Mise à jour de la longueur de la barre
        document.getElementById('form-progress-bar-steps').style.width =
            ((positionCarrousel / 3) * 100).toString() + '%';

        let oldButton = document.getElementById('progressBarStep' + oldVal.toString());
        let newButton = document.getElementById('progressBarStep' + val.toString());

        // On change la couleur des boutons de la progress bar manuellement.

        if (val < oldVal) {
            for (let i = oldVal; i >= val; i--) {
                oldButton = document.getElementById('progressBarStep' + i.toString());
                oldButton.classList.remove('btn-primary');
                oldButton.classList.add('pe-none');
                oldButton.classList.add('btn-secondary');
            }
        }

        newButton.classList.remove('btn-secondary');
        newButton.classList.remove('pe-none');
        newButton.classList.add('btn-primary');
    }

    function handleCarouselRedirection(val) {
        carouselRedirection = val;
        const infoRedirection = document.getElementById('infoRedirection');
        infoRedirection.style.display = carouselRedirection ? 'block' : 'none';
    }
    //alert('carouselRedirection : ' + carouselRedirection);

    function hideAlert() {
        const infoRedirection = document.getElementById('infoRedirection');
        infoRedirection.style.display = 'none';
    }

    function getFormDate() {
        return formDate;
    }

    return (
        <div>
            <Header />
            <main>
                {isLoading && isLoadingSheetsDate && (
                    <div className="position-fixed p-2 w-100">
                        <div className="alert alert-primary" role="alert">
                            Chargement des informations...
                            <br />
                            <div className="spinner-grow text-primary m-3" role="status">
                                <span className="visually-hidden">Chargement...</span>
                            </div>
                            <div className="spinner-grow text-light m-3" role="status">
                                <span className="visually-hidden">Chargement...</span>
                            </div>
                            <div className="spinner-grow text-danger m-3" role="status">
                                <span className="visually-hidden">Chargement...</span>
                            </div>
                        </div>
                    </div>
                )}

                {!isLoading && !apiInformationError && !isLoadingSheetsDate && !apiSheetsDateError && (
                    <>
                        <div
                            className="d-flex align-items-center justify-content-center"
                            style={{ width: '50%', margin: '0 auto' }}
                        >
                            <div className="position-relative mt-5 w-75 mb-5">
                                <div className="progress" style={{ height: '3px' }}>
                                    <div
                                        id="form-progress-bar-steps"
                                        className="progress-bar"
                                        role="progressbar"
                                        aria-label="Progress"
                                        style={{ width: ((positionCarrousel / 3) * 100).toString() + '%' }}
                                        aria-valuenow="50"
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                    ></div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div>
                                        <input
                                            id="progressBarStep0"
                                            value="1"
                                            type="submit"
                                            onClick={() => handleCarouselRedirection(true)}
                                            className="btn-primary position-absolute translate-middle btn btn-sm rounded-pill"
                                            style={{ width: '2.5rem', height: '2.5rem' }}
                                            data-bs-target="#carouselHome"
                                            data-bs-slide-to="0"
                                        ></input>
                                    </div>
                                    <div>
                                        <input
                                            id="progressBarStep1"
                                            value="2"
                                            type="submit"
                                            className="pe-none btn-secondary position-absolute translate-middle btn btn-sm rounded-pill"
                                            style={{ width: '2.5rem', height: '2.5rem' }}
                                            onClick={() => handleCarouselRedirection(true)}
                                            data-bs-target="#carouselHome"
                                            data-bs-slide-to="1"
                                        ></input>
                                    </div>
                                    <div>
                                        <input
                                            id="progressBarStep2"
                                            value="3"
                                            type="submit"
                                            className="pe-none btn-secondary position-absolute translate-middle btn btn-sm rounded-pill"
                                            style={{ width: '2.5rem', height: '2.5rem' }}
                                            onClick={() => handleCarouselRedirection(true)}
                                            data-bs-target="#carouselHome"
                                            data-bs-slide-to="2"
                                        ></input>
                                    </div>
                                    <div>
                                        <input
                                            id="progressBarStep3"
                                            value="4"
                                            type="submit"
                                            className="pe-none btn-secondary position-absolute translate-middle btn btn-sm rounded-pill"
                                            style={{ width: '2.5rem', height: '2.5rem' }}
                                            onClick={() => handleCarouselRedirection(true)}
                                            data-bs-target="#carouselHome"
                                            data-bs-slide-to="3"
                                        ></input>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex justify-content-center">
                            <div
                                ref={infoRedirectionRef}
                                id="infoRedirection"
                                className="alert alert-info alert-dismissible fade show fw-bold w-75 "
                                role="alert"
                            >
                                Assurez-vous de naviguer avec les boutons "Suivant" au fil du remplissage de la fiche
                                pour sauvegarder vos changements !
                                <button
                                    type="button"
                                    className="btn-close"
                                    onClick={() => hideAlert()}
                                    aria-label="Close"
                                ></button>
                            </div>
                        </div>
                        <div
                            id="carouselHome"
                            className="carousel carousel-dark slide w-100"
                            data-mdb-interval="false"
                            data-bs-ride="false"
                        >
                            <div className="carousel-inner" id="carouselHomeForm">
                                <div className="carousel-item active">
                                    {apiInformationData && apiSheetsDate && (
                                        <HomeForm
                                            presetData={props.presetData}
                                            data={apiInformationData.persons}
                                            dates={apiSheetsDate}
                                            handleHomeFormSubmit={handleHomeFormSubmit}
                                            handlePositionCarousel={handlePositionCarousel}
                                            handleCarouselRedirection={handleCarouselRedirection}
                                        />
                                    )}
                                </div>

                                <div className="carousel-item" id="carouselAttendanceForm">
                                    {apiInformationData && (
                                        <AttendanceForm
                                            presetData={props.presetData}
                                            data={apiInformationData.persons}
                                            handleAttendanceSubmit={handleAttendanceSubmit}
                                            handlePositionCarousel={handlePositionCarousel}
                                            handleCarouselRedirection={handleCarouselRedirection}
                                            hourData={apiInformationData.parametres['heures/jour']}
                                            getFormDate={getFormDate}
                                        />
                                    )}
                                </div>

                                <div className="carousel-item" id="carouselInterventionForm">
                                    {apiInformationData && (
                                        <InterventionForm
                                            presetData={props.presetData}
                                            data={apiInformationData}
                                            getFormData={getFormData}
                                            data402={apiInformationData.parametres['liste_402']}
                                            handleInterventionSubmit={handleInterventionSubmit}
                                            handlePositionCarousel={handlePositionCarousel}
                                            handleCarouselRedirection={handleCarouselRedirection}
                                        />
                                    )}
                                </div>

                                <div className="carousel-item" id="carouselRecapElem">
                                    {apiInformationData && (
                                        <RecapElem
                                            presetData={props.presetData}
                                            constSheetID={props.constSheetID}
                                            sendRecapUpdater={sendRecapUpdater}
                                            data={apiInformationData}
                                            getFormData={getFormData}
                                            handlePositionCarousel={handlePositionCarousel}
                                            handleCarouselRedirection={handleCarouselRedirection}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </>
                )}

                {(hasExpiredError() && handleExpiredSession()) ||
                    (hasOtherError() && (
                        <div
                            className="alert alert-danger mt-5 ms-5 me-5"
                            role="alert"
                            onClick={(_) => {
                                window.location.reload();
                            }}
                        >
                            <h4 className="alert-heading">Erreur!</h4>
                            <p>
                                Il semblerait qu'il y ait eu une erreur lors de la récupération des données sur le
                                serveur. Si cela continue après le rafraîchissement de la page, essayez de vous
                                déconnecter et de vous reconnecter.
                            </p>
                            <p>Si cela ne fonctionne toujours pas, contactez votre administrateur réseau..</p>
                            <p>
                                <strong>Status HTTP: {apiInformationError.error}</strong>
                            </p>
                            <hr />
                            <p className="mb-0">Cliquez sur l'encadré pour rafraîchir la page.</p>
                        </div>
                    ))}
            </main>
        </div>
    );
}
